<template>
  <v-container class="pa-3">
    <v-row
      no-gutters
      justify="center"
    >
      <v-col cols="7">
        <h2 class="text-body-1 text-uppercase mb-3">
          Alterar senha
        </h2>

        <v-stepper
          v-model="stepIndex"
        >
          <v-stepper-header>
            <div
              v-for="header in stepHeaders"
              :key="header.title"
            >
              <v-stepper-step
                :complete="stepIndex > header.step"
                :step="header.step"
              >
                {{ header.title }}
              </v-stepper-step>

              <v-divider />
            </div>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <form-cpf @cpf-returned="handleCpfFormEvent" />
            </v-stepper-content>

            <v-stepper-content
              step="2"
            >
              <form-email
                v-if="stepIndex === 2"
                :user="user"
                @email-returned="changeStep"
              />
            </v-stepper-content>

            <v-stepper-content step="3">
              <form-auth-user
                @go-back="changeStep"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ChangePasswordForm',
  components: {
    'form-cpf': () => import('./FormCPF'),
    'form-email': () => import('./FormEmail'),
    'form-auth-user': () => import('./FormChangePasswordAuthUser'),
  },
  data() {
    return {
      stepIndex: 1,
      user: {
        email: '',
        cpf: '',
        isAuthUser: '',
      },
      stepHeaders: [
        {
          step: 1,
          title: 'Informe CPF',
        },
        {
          step: 2,
          title: 'Enviando código secreto ao e-mail',
        },
        {
          step: 3,
          title: 'Alterando a Senha',
        },
      ],
    };
  },
  methods: {
    changeStep(step) {
      if (Number(step)) {
        this.stepIndex = step;
      }
    },

    handleCpfFormEvent({ email, isAuthUser, cpf }) {
      this.user.cpf = cpf;
      this.user.email = email;
      this.user.isAuthUser = isAuthUser;

      if (isAuthUser) {
        this.changeStep(2);
      } else {
        this.changeStep(3);
      }
    },
  },
};
</script>
